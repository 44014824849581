import React from "react";
import PropTypes from "prop-types";

import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Home as HomeIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";


function NavBar(props) {
	const history = useHistory();

	const homePage = () => {
		history.push("/");
	};

	return (
		<AppBar position="static">
			<Toolbar style={{ justifyContent: "space-between" }}>
				<IconButton onClick={homePage} color="inherit">
					<HomeIcon />
				</IconButton>
				<Typography variant="h6">
					Outreaching
				</Typography>
				{props.children}
			</Toolbar>
		</AppBar>
	);
}

NavBar.propTypes = {
	children: PropTypes.node
};
export default NavBar;
