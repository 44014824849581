import React from "react";
import "./App.css";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";

import { Home, Login, User } from "./pages";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Redirect to="/login" />
				</Route>
				<Route path="/login">
					<Login />
				</Route>
				<Route path="/home">
					<Home />
				</Route>
				<Route path="/user/:username">
					<User />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
