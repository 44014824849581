import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#ffa4a2",
			main: "#e57373",
			dark: "#af4448",
			contrastText: "#000",
		},
		secondary: {
			light: "#ffeeff",
			main: "#f8bbd0",
			dark: "#c48b9f",
			contrastText: "#000",
		},
	},
});

export default theme;
