import React, { useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "./shared/NavBar";
import NavBarUser from "./shared/NavBarUser";
import config from "../config";
import {
	Button,
	Card,
	CardContent,
	CardMedia,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	TextField,
	Typography
} from "@material-ui/core";
import {
	Autocomplete
} from "@material-ui/lab";
import { Add } from "@material-ui/icons";

import feeds from "./dummyData/feeds.json";
import allCategories from "./dummyData/categories.json";
const feedCategories = Array.from(new Set(feeds.map(i => i.tags).flat()));

function User() {
	const { username } = useParams();
	const [ interests, setInterests ] = useState(feedCategories);
	const [ newInterests, setNewInterests ] = useState([]);
	const [ openDialog, setOpenDialog ] = useState(false);
	// TODO: Grab these values from the API
	const name = "Ceda EI";
	const about = "I am a 20-year old student from India who has an interest in system administration and programming. I am a Linux user who likes to rice his desktop and have some experience managing servers.";

	function handleOpen() {
		setOpenDialog(true);
	}

	function handleClose() {
		setNewInterests([]);
		setOpenDialog(false);
	}

	function handleAdd() {
		setInterests([...interests, ...newInterests]);
		handleClose();
	}
	return (<>
		<NavBar>
			<NavBarUser />
		</NavBar>
		<div className="user base">
			<div className="user-card">
				<Card>
					<CardMedia
						image={`${config.profileServer}/${username}.jpg`}
						title={username}
						component="img"
					/>
					<CardContent>
						<Typography gutterBottom variant="h2" component="h1">
							{name}
						</Typography>
						<Typography variant="body2" component="h2">
							@{username}
						</Typography>
					</CardContent>
				</Card>
			</div>
			<div className="user-profile">
				<Card>
					<CardContent>
						<Typography gutterBottom variant="h2">
							About Me
						</Typography>
						<Typography variant="body1">
							{about}
						</Typography>
					</CardContent>
				</Card>
				<Card style={{ marginTop: "2em" }}>
					<CardContent>
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<Typography gutterBottom variant="h2">
								Interests
							</Typography>
							<IconButton onClick={handleOpen}>
								<Add />
							</IconButton>
						</div>
						<Typography variant="body1">
							{interests.map((tag, idx) => <Chip label={tag} key={idx} style={{marginRight: "1em", marginBottom: "1em"}}/>)}
						</Typography>
					</CardContent>
				</Card>
			</div>
		</div>
		<Dialog open={openDialog} onClose={handleClose} fullWidth={true} maxWidth="sm">
			<DialogTitle>Add Interests</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Add interests to follow here.
				</DialogContentText>
				<Autocomplete
					multiple
					limitTags={2}
					options={allCategories}
					renderInput={(params) => (
						<TextField {...params} variant="outlined" label="Interests" placeholder="Interests" />
					)}
					value={newInterests}
					onChange={(_, e) => setNewInterests(e)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button disabled={newInterests.length === 0} onClick={handleAdd} color="primary">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	</>);
}

export default User;
