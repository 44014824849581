import React, { useState } from "react";
import {
	Input,
	InputLabel,
	Button,
	FormControl,
	Paper,
	Typography,
	Avatar,
} from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import NavBar from "./shared/NavBar";
import {useHistory} from "react-router-dom";

function Login() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const history = useHistory();

	function login(e) {
		setUsername("");
		setPassword("");
		history.push("/home");
		e.preventDefault();
	}

	return (
		<>
			<NavBar>
				<div></div>
			</NavBar>
			<Paper style={{maxWidth: 350, margin: "auto", marginTop: 100, padding: 20}}>
				<Avatar style={{margin: "auto"}}>
					<LockOutlined />
				</Avatar>
				<Typography component="h1" variant="h5" style={{margin: "auto", textAlign: "center"}}>
					Sign In
				</Typography>
				<form
					onSubmit={login}>
					<FormControl margin="normal" required fullWidth>
						<InputLabel
							htmlFor="username"
						>Username</InputLabel>
						<Input
							type="text"
							id="username"
							value={username}
							onChange={evt => setUsername(evt.target.value)}
						/>
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel
							htmlFor="password"
						>Password</InputLabel>
						<Input
							type="password"
							id="password"
							value={password}
							onChange={evt => setPassword(evt.target.value)}
						/>
					</FormControl>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						style={{
							marginTop: 40
						}}
					>Submit</Button>
				</form>
			</Paper>
		</>
	);
}

export default Login;
