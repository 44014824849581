import React, { useState } from "react";
import PropTypes from "prop-types";

import {
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Checkbox,
	Chip,
	FormControlLabel,
	IconButton,
	Link,
	Typography
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Favorite, Link as LinkIcon, Delete } from "@material-ui/icons";
import NavBar from "./shared/NavBar";
import NavBarUser from "./shared/NavBarUser";

import config from "../config";
// TODO: Replace with API accessed feeds
import feeds from "./dummyData/feeds.json";
const feedCategories = Array.from(new Set(feeds.map(i => i.tags).flat()));

function FeedCard({ feed }) {
	const [filled, setFilled] = useState(Math.random() > 0.7);
	let style = {};
	if (filled) {
		style={
			color: red.A400
		};
	}
	return (
		<Card
			key={feed.id}
			style={{
				maxWidth: "500px",
				width: "100%",
				margin: "20px 0",
			}}
		>
			<CardActionArea>
				<CardMedia
					image={`${config.thumbnailServer}/${feed.id}.jpg`}
					title={feed.title}
					component="img"
				/>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{feed.title}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="h2">
						{feed.description}
					</Typography>
					{feed.tags.map((tag, idx) => <Chip label={tag} key={idx} style={{marginRight: "1em"}}/>)}
				</CardContent>
			</CardActionArea>
			<CardActions>
				<IconButton style={style} onClick={() => setFilled(!filled)}>
					<Favorite />
				</IconButton>
				<Link href={feed.url}>
					<IconButton>
						<LinkIcon />
					</IconButton>
				</Link>
			</CardActions>
		</Card>
	);
}

FeedCard.propTypes = {
	feed: PropTypes.object,
};

function App() {
	const [categories, setCategories] = useState(feedCategories);

	function toggleCategory(category) {
		if (categories.includes(category))
			setCategories(categories.filter(i => i !== category));
		else
			setCategories([...categories, category]);
	}

	const visibleFeeds = feeds.filter(i => {
		for (let tag of i.tags) {
			for (let category of categories) {
				if (category == tag)
					return true;
			}
		}
		return false;
	});

	return (<>
		<NavBar>
			<NavBarUser />
		</NavBar>
		<div className="base">
			<div className="feeds">
				{visibleFeeds.length === 0 ? <Typography variant="h5" style={{color: "#666"}}component="div"> Nothing here (╯°□°)╯︵ ┻━┻</Typography>: null}
				{visibleFeeds.map(feed => <FeedCard key={feed.id} feed={feed} />)}
			</div>
			<div className="filters">
				<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
					<Typography variant="h5" component="p">Filters</Typography>
					<IconButton style={{color: red.A200}} onClick={() => setCategories([])}>
						<Delete />
					</IconButton>
				</div>
				{feedCategories.sort().map(category => (
					<FormControlLabel
						key={category}
						control={
							<Checkbox
								checked={categories.includes(category)}
								onChange={() => toggleCategory(category)}
								name="checkedF"
							/>
						}
						label={category}
					/>
				))}
			</div>
		</div>
	</>);
}

export default App;
